<template>
    <div>
      <admin-comp :type="type"></admin-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from "vue-router";
import AdminComp from "@/components/admin/admin/AdminComp.vue";

export default defineComponent({
    name: "view-admin",
    components : {AdminComp},

    setup() {

        const route = useRoute();
        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View Admin", ["Admins"] , 'create-admin');
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
